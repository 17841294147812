var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
const homeRegionActive = document.querySelectorAll('.homeSavoie');

if(width > 767) {
    homeRegionActive.forEach(function(reg) {
        reg.classList.add('active');
    });
}

const nav = document.getElementById('mainNav');
const burger = document.getElementById('openNav');
const closeNav = document.getElementById('closeNav');
const navOverlay = document.getElementById('navOverlay');


burger.addEventListener('click', () => {
    burger.classList.toggle('active');
    nav.classList.toggle('active');
    navOverlay.classList.toggle('active');
});

closeNav.addEventListener('click', () => {
    burger.classList.remove('active');
    nav.classList.remove('active');
    navOverlay.classList.remove('active');
});

document.addEventListener('click', (event) => {
    if (!nav.contains(event.target) && !burger.contains(event.target)) {
        burger.classList.remove('active');
        nav.classList.remove('active');
        navOverlay.classList.remove('active');
    }
});

// CHEESE HOVER
const homeBlocks = document.querySelectorAll('.home--section-block');

if(width > 1024) {
    homeBlocks.forEach(function(block) {
        block.addEventListener('mouseover', () => {
            const img = block.firstChild.nextSibling;
            const copy = img.nextSibling.nextSibling;
    
            let copyHeight = copy.offsetHeight;
            
            img.setAttribute("style","height:calc(100% - " + copyHeight + "px);");
    
        });
        block.addEventListener('mouseout', () => {
            const img = block.firstChild.nextSibling;
            const copy = img.nextSibling.nextSibling;
    
            let copyHeight = copy.offsetHeight;
            img.setAttribute("style","height:100%;");
    
        });
    });
}
const form = document.getElementById('contactForm');

async function handleSubmit(event) {
event.preventDefault();
var status = document.getElementById("formStatus");
var data = new FormData(event.target);
fetch('/contact-us', {
  method: form.method,
  body: data,
  headers: {
    'Accept': 'application/json'
}
}).then(response => {
  if (response.ok) {
    status.innerHTML = "<p class='thanks'>Thanks for your submission!</p>";
    form.reset()
  } else {
    response.json().then(data => {
    if (Object.hasOwn(data, 'errors')) {
      status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
    } else {
      status.innerHTML = "<p>Oops! There was a problem submitting your form</p>"
    }
  })
}
}).catch(error => {
  status.innerHTML = "<p>Oops! There was a problem submitting your form</p>"
});
}
if(form) {
  form.addEventListener("submit", handleSubmit)
}